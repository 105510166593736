html,
body {
    height: 100%;
    font-family: "Lato", sans-serif;
    scroll-behavior: smooth;
}

body {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
    background-size: cover !important;
}

.container {
    height: 100%;
}

a {
    color: rgb(16, 16, 16);
    text-decoration: none !important;
}

body {
    background: rgb(220, 220, 220);
}

label {
    margin: initial;
}

#engineRevLogo {
    width: 2rem;
    border-radius: 10%;
    margin-top: -15px;
}

#engineRevLogoAboutPage {
    border-radius: 10%;
    margin-top: -15px;
}

#heading {
    font-family: "Chivo", sans-serif;
}

#menuDropdownButton {
    border-radius: 0;
    color: rgb(212, 212, 212);
}

#menuDropdownContainer {
    width: 100%;
}

.dropdown-menu {
    background: rgb(48, 48, 48);
    min-width: 5rem;
    border-radius: 0;
}

.section {
    background: rgba(245, 245, 245, 0.8);
    margin-bottom: 2rem;
    height: initial !important;
    padding: 1.5rem;
}

.accountModal {
    margin-top: 3rem;
    background: rgba(255, 255, 255, 0.7);
}

.ReactModal__Overlay {
    background: rgba(32, 32, 32, 0.5) !important;
    box-shadow: 0px 2px 30px 1px;
    z-index: 1;
}

.modalBody {
    background: rgb(235, 235, 235);
    border-radius: 3px;
}

.modalShadow {
    box-shadow: 0px 2px 15px 1px;
}

input {
    width: 100%;
}

.editIcon,
.deleteIcon {
    padding: 3px;
    width: 2em;
}

.navbar {
    background: rgb(48, 48, 48);
    padding: 2px;
    position: fixed;
    width: 100%;
    z-index: 1;
    margin-left: initial;
}

#navBarLoggedOut {
    position: fixed;
}

#vehicleLogInformation,
#addVehicleInputForm {
    margin-top: 3em;
}

.navbar-nav {
    position: fixed;
    right: 0;
}

#aboutEngineRevContainer {
    background: rgb(245, 245, 245);
    padding: 1rem;
    margin-bottom: 0.5rem;
}

.box {
    background: rgb(235, 235, 235);
    padding: 1em;
    margin-bottom: 1rem;
}

.innerBox {
    padding-left: 1rem;
    padding-right: 1rem;
}

.threadTitleTextArea {
    width: 100%;
    height: 5rem !important;
    margin: auto;
}

.threadDescriptionTextArea {
    width: 100%;
    height: 12rem !important;
    margin: auto;
    margin-top: 1rem;
}

.commentsBox {
    width: 100%;
    height: 5rem;
    margin: auto;
}

.required {
    color: rgb(255, 0, 0);
}

.resetButton,
.logSubmitBtn,
.centerDesktopDisplay {
    text-align: center;
}

.cancelBtn {
    margin-right: 5px;
}

#sortThreadsDropdown {
    color: rgb(0, 0, 0);
    box-shadow: 0 1px 1px 1px rgb(92, 92, 92);
    margin-right: 0.5rem;
    cursor: pointer;
    height: 1.8rem;
}

#applySortToThreads {
    color: black;
    background: rgb(225, 225, 225);
    border: none;
    cursor: pointer;
    transition-duration: 0.5s;
    border: 1px solid rgb(255, 255, 255);
    box-shadow: 0 1px 1px 1px rgb(92, 92, 92);
}

.addBtn,
.resetButton,
.cancelBtn,
.deleteBtn,
.backButton,
.backHomeBtn,
.backToTopButton,
.standardButton,
#applyThemeButton,
#submitNewEmailButton,
#submitNewPasswordButton,
#submitNewDisplayNameButton,
#submitNewProfilePictureButton,
#submitNewBackgroundPictureButton,
#resetNewBackgroundPictureButton,
#resetNewProfilePictureButton,
#resetNewDisplayNameButton {
    border: none;
    cursor: pointer;
    transition-duration: 0.5s;
    border: 1px solid rgb(255, 255, 255);
    box-shadow: 0 1px 1px 1px rgb(92, 92, 92);
    width: 7rem;
    color: rgb(0, 0, 0);
    font-weight: normal;
}

.editActionButton {
    color: rgb(0, 0, 0);
    border: 1px solid rgb(255, 255, 255);
    box-shadow: 0 1px 1px 1px rgb(92, 92, 92);
}

.addBtn,
.cancelBtn,
.editActionButton,
.standardButton,
.resetButton,
#applyThemeButton,
#submitNewEmailButton,
#submitNewPasswordButton,
#submitNewDisplayNameButton,
#submitNewProfilePictureButton,
#submitNewBackgroundPictureButton,
#resetNewBackgroundPictureButton,
#resetNewProfilePictureButton,
#resetNewDisplayNameButton {
    background: rgb(225, 225, 225);
}

.deleteBtn,
.deleteActionButton,
.backButton,
.backHomeBtn {
    background: rgb(220, 64, 48);
    color: rgb(255, 255, 255);
    border: 1px solid rgb(255, 255, 255);
    box-shadow: 0 1px 1px 1px rgb(92, 92, 92);
}

#deleteBtn {
    margin: auto;
}

.backToTopButton,
#resetNewBackgroundPictureButton,
#resetNewProfilePictureButton,
#resetNewDisplayNameButton {
    float: right;
}

.addBtn:hover,
.resetButton:hover,
.cancelBtn:hover,
.deleteBtn:hover,
.backButton:hover,
.backHomeBtn:hover,
.backToTopButton:hover,
.editActionButton,
.deleteActionButton,
.standardButton:hover,
#applyThemeButton:hover,
#submitNewEmailButton:hover,
#submitNewPasswordButton:hover,
#submitNewDisplayNameButton:hover,
#submitNewProfilePictureButton:hover,
#submitNewBackgroundPictureButton:hover,
#resetNewBackgroundPictureButton:hover,
#resetNewProfilePictureButton:hover,
#resetNewDisplayNameButton:hover,
#applySortToThreads:hover {
    transition-duration: 0.2s;
}

#addLogBackButton:hover,
.deleteBtn:hover,
.deleteActionButton:hover,
.backButton:hover,
.backHomeBtn:hover {
    background: rgb(255, 128, 128);
}

.addBtn:hover,
.cancelBtn:hover,
.editActionButton:hover,
.standardButton:hover,
.resetButton:hover,
#applyThemeButton:hover,
#submitNewEmailButton:hover,
#submitNewPasswordButton:hover,
#submitNewDisplayNameButton:hover,
#submitNewProfilePictureButton:hover,
#submitNewBackgroundPictureButton:hover,
#resetNewBackgroundPictureButton:hover,
#resetNewProfilePictureButton:hover,
#resetNewDisplayNameButton:hover,
#applySortToThreads:hover {
    background: rgb(255, 255, 255);
}

#signUpNavButton {
    color: rgb(220, 64, 48);
}

#signOutNavButton {
    color: rgb(128, 128, 128);
}

#forumNavButton,
#accountNavButton,
#accountAboutButton,
#accountReleaseNotesButton,
#signInNavButton {
    transition-duration: 0.2s;
    color: rgb(212, 212, 212);
}

#forumNavButton:hover,
#accountNavButton:hover,
#accountAboutButton:hover,
#accountReleaseNotesButton:hover,
#signOutNavButton:hover,
#signInNavButton:hover,
#menuDropdownButton:hover {
    transition-duration: 0.1s;
    background: rgb(128, 128, 128);
    color: rgb(255, 255, 255);
}

#signUpNavButton:hover {
    background: rgb(128, 128, 128);
    color: rgb(255, 255, 255);
}

#deleteThreadButton,
#addLogBackButton {
    background: rgb(220, 64, 48);
    color: rgb(255, 255, 255);
}

#deleteThreadButton,
#saveEditedThreadTitleButton,
#cancelEditThreadTitleButton,
#addLogBackButton,
#addServiceLogButton,
#addLogResetInputFieldsButton,
#addLogSortLogsButton,
#editVehicleNameButton,
#printPageButton,
#printPageViaDeleteButton {
    width: 7rem;
}

.nav-link:hover {
    transition-duration: 0.1s;
    background: rgb(64, 64, 64);
}

.nav-link {
    transition-duration: 0.2s;
}

.editComment,
#editThreadTitleButton {
    color: rgb(108, 117, 125);
    transition-duration: 0.2s;
}

.nav-link,
.editComment,
#editThreadTitleButton,
#advancedSettingsToggle {
    cursor: pointer;
}

#advancedSettingsToggle {
    color: inherit;
}

.navbar-toggler {
    border-color: rgb(255, 64, 48) !important;
    background: rgb(255, 255, 255);
}

.notLoggedInImages {
    width: 3rem;
}

#notLoggedInMainImage {
    width: 15rem;
}

#createAccountLogo {
    width: 5%;
    margin-right: 5px;
}

.warningImage,
.signOutImage {
    width: 50%;
}

.checkMarkImage {
    width: 10%;
    margin-left: 8px;
    margin-right: -495px;
}

.vehicleItemList {
    padding: 0.5rem;
    margin-bottom: 0.5rem !important;
    transition-duration: 0.2s;
}

.vehicleItemList:hover {
    transition-duration: 0.1s;
}

.Toastify__toast-container--top-right {
    top: 3em !important;
    right: 5px !important;
}

#applicationName {
    color: rgb(220, 220, 220);
    padding-left: 1rem;
    transition-duration: 0.2s;
}

#applicationName:hover {
    color: rgb(255, 255, 255);
    transition-duration: 0.2s;
}

.serviceLogActionDropdown {
    border-radius: 5px;
    margin: auto;
    width: 100%;
}

.showUnderMobileDisplay {
    display: none;
}

#serviceLogDateInput,
#serviceLogMileageInput,
#serviceLogServiceInput {
    height: 2em;
}

#forumPage,
#accountPage,
#releaseNotesPage,
#noMatch,
#noAuthorization {
    padding-top: 4em;
    margin-top: 0em !important;
}

#noMatchImage {
    width: 20em;
}

#showUniqueUserId,
#showMaskUniqueUserId {
    display: initial;
}

#hideUniqueUserId,
#hideMaskUniqueUserId {
    display: none;
}

#scrollableProfilePictureAccountDetails {
    max-height: 35rem;
    overflow: auto;
}

#scrollableProfilePicture,
#scrollableBackgroundPicture {
    max-height: 17rem;
    overflow: auto;
    margin-top: 1rem;
}

.topLogActionButtons {
    text-align: center;
}

.noFlex {
    display: initial;
}

.noFlexWrap {
    flex-wrap: initial !important;
}

#addVehicleButton,
#resetVehicleInputFieldsButton {
    width: 7.5rem;
    margin: 4px;
}

.imageMobileDisplay {
    width: 2rem;
}

#forgotPassword,
#signUpLink,
#signInLink {
    cursor: pointer;
    color: rgb(200, 0, 0);
    transition-duration: 0.2s;
}

#forgotPassword:hover,
#signUpLink:hover,
#signInLink:hover {
    color: rgb(255, 0, 0);
    transition-duration: 0.2s;
}

.Toastify__toast--error {
    background: rgba(225, 0, 0, 0.8);
}

.Toastify__toast--info {
    background: rgba(32, 150, 255, 0.8);
}

.Toastify__toast--success {
    background: rgba(16, 200, 31, 0.8);
}

.Toastify__toast--warning {
    background: rgba(255, 168, 30, 0.8);
}

.underline {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    overflow: hidden;
}
  
.underline:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    right: 50%;
    bottom: 0;
    background: rgb(220, 64, 48);
    height: 4px;
    -webkit-transition-property: left, right;
    transition-property: left, right;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
  
.underline:hover:before,
.underline:focus:before,
.underline:active:before {
    left: 0;
    right: 0;
}

#confirmDeleteVehicleButton:disabled {
    background: rgb(128, 128, 128);
    color: rgb(192, 192, 192);
    cursor: default;
}

#mainPageProfilePicture {
    box-shadow: 0px 2px 5px rgb(32, 32, 32);
    transition-duration: 0.3s;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    object-fit: cover;
    margin-right: 5px;
}

#mainPageProfilePicture:hover {
    box-shadow: 0px 2px 5px rgb(92, 92, 92);
    transition-duration: 0.2s;
}

#profilePicture,
#profilePicturePreview,
#backgroundPicture {
    width: 70%;
}

#loadingScreen {
    padding-top: 5rem;
}

#loadingGif {
    width: 5rem;
}

.serviceLogFullDetails,
.releaseNote,
.threadDetails {
    background: rgb(255, 255, 255);
    padding: 1rem;
    margin-top: 1rem;
}

.threadCredentials,
.threadCommentCredentials,
.releaseNoteCredentials,
.votes {
    font-size: 12px;
}

.threadCommentCredentials {
    width: 79%;
}

.smallMarginTop {
    margin-top: 1rem;
}

.smallPadding {
    padding: 1rem;
}

.noMarginDesktopDisplay {
    margin-left: 0px;
    margin-right: 0px;
}

.oneThreadHr,
.oneUpdateHr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.modal-footer {
    border-top: initial;
}

.modal-header {
    border-bottom: initial;
}

.votes,
.alignRightButtonsDesktopDisplay {
    text-align: right;
}

.paddingDesktopDisplay {
    background: rgb(245, 245, 245);
    padding: 0.5rem;
    margin-left: 5px;
    margin-right: 5px;
}

.highlightComment {
    border-left: 5px solid rgb(0, 192, 92) !important;
}

#threadCommentTextArea {
    width: 100%;
    height: 15rem;
}

#updatedReleaseNotesTextArea,
#updatedKnownIssuesTextArea {
    width: 100%;
    height: 11rem;
}

.breakWord {
    word-wrap: break-word;
}

.updateChangesDeletePreview,
.knownIssuesDeletePreview {
    max-height: 11rem;
    overflow: auto;
    word-wrap: break-word;
}

.smallBottomMargin {
    margin-bottom: 1rem;
}

.noPaddingLeftDesktopDisplay {
    padding-left: initial;
}

.noPaddingRightDesktopDisplay {
    padding-right: initial;
}

#noPermissionToViewVehicleLogs {
    margin-top: 4rem;
}

footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgb(48, 48, 48);
    text-align: center;
    padding: 0.5rem;
}

.footerAboutLink,
.footerUpdatesLink {
    transition-duration: 0.2s;
    color: rgb(212, 212, 212);
}

.footerAboutLink:hover,
.footerUpdatesLink:hover {
    transition-duration: 0.1s;
    color: rgb(255, 255, 255);
}

.wrapword {
    white-space: -moz-pre-wrap !important;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    white-space: pre-wrap;
    word-wrap: break-word;
    white-space: -webkit-pre-wrap;
    word-break: break-all;
    white-space: normal;
}

#categories {
    padding-left: 1rem;
    padding-right: 1rem;
}

.threadLink {
    transition-duration: 0.5s;
}

.threadLink:hover {
    color: rgb(0, 0, 0);
    transition-duration: 0.1s;
    box-shadow: 2px 2px 5px 1px rgb(0, 0, 0);
}

.deleteActionButton {
    width: 3rem;
}

.editActionButton {
    width: 4rem;
}

.threadCommentButton {
    color: rgb(108, 117, 125);
    background: none;
    border: none;
    margin-left: -0.35rem;
    transition-duration: 0.2s;
}

.editComment:hover,
.threadCommentButton:hover,
#editThreadTitleButton:hover {
    color: initial;
    transition-duration: 0.1s;
}

#threadCategoryDropdown {
    color: rgb(0, 0, 0);
    box-shadow: 0 1px 1px 1px rgb(92, 92, 92);
    cursor: pointer;
    border-radius: 3px;
}

#themeSelectionDropdown {
    color: rgb(0, 0, 0);
    width: 100%;
    height: 100%;
}

#downloadEventLogsButton {
    background: rgb(225, 225, 225);
    transition-duration: 0.2s;
    width: 7rem;
    cursor: pointer;
    border: 1px solid rgb(255, 255, 255);
    box-shadow: 0 2px 2px 0 rgb(92, 92, 92);
    width: 6.8rem;
}

#downloadEventLogsButton:hover {
    background: rgb(255, 255, 255);
    transition-duration: 0.1s;
}

.Toastify__toast-body {
    color: rgb(255, 255, 255);
}

.Toastify__progress-bar--info {
    background: rgb(32, 92, 255);
}

.Toastify__progress-bar--success {
    background: rgb(0, 255, 128);
}

#loadingAnimation {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

#loadingAnimation div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
}

#loadingAnimation div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #fff;
}

#loadingAnimation div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

#loadingAnimation div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

#loadingAnimation div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

#loadingAnimation div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

#loadingAnimation div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

#loadingAnimation div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

#loadingAnimation div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

#loadingAnimation div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

#loadingAnimation div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

#loadingAnimation div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

#loadingAnimation div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

#loadingAnimation div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

.fadeIn1 {
    animation:0.2s ease 0s normal forwards 1 fadein;
    -webkit-animation:0.2s ease 0s normal forwards 1 fadein;
    opacity:1
}

.fadeIn2 {
    animation:0.3s ease 0s normal forwards 1 fadein;
    -webkit-animation:0.3s ease 0s normal forwards 1 fadein;
    opacity:1
}

.fadeIn3 {
    animation:0.4s ease 0s normal forwards 1 fadein;
    -webkit-animation:0.4s ease 0s normal forwards 1 fadein;
    opacity:1
}

.fadeIn4 {
    animation:0.5s ease 0s normal forwards 1 fadein;
    -webkit-animation:0.5s ease 0s normal forwards 1 fadein;
    opacity:1
}

.fadeIn5 {
    animation:0.6s ease 0s normal forwards 1 fadein;
    -webkit-animation:0.6s ease 0s normal forwards 1 fadein;
    opacity:1
}

.fadeIn {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
    animation-duration: 0.1s;
}

@keyframes fadeInOpacity {
	0% { opacity: 0 }
	100% { opacity: 1 }
}

@keyframes fadein {
    0% { opacity: 0 }
    40% { opacity: 0 }
    100% { opacity: 1 }
}

@-webkit-keyframes fadein {
    0% { opacity: 0 }
    40% { opacity: 0 }
    100% { opacity: 1 }
}

@keyframes lds-spinner {
    0% { opacity: 1 }
    100% { opacity: 0 }
}

@media print {
	.hideWhilePrinting {
        display: none;
    }

    .Toastify__toast-container {
        display: none;
    }

    .serviceLogFullDetails {
        font-weight: initial !important;
        padding: initial !important;
    }
}

@media only screen and (max-width: 1200px) {
    .actionButtonsMobileDisplay {
        max-width: 45%;
    }
}

@media only screen and (max-width: 992px) {
    .navbar-nav {
        position: initial;
        right: 0;
        text-align: center;
    }

    #forumNavButton,
    #accountNavButton,
    #accountAboutButton,
    #accountReleaseNotesButton,
    #signOutNavButton,
    #signInNavButton,
    #signUpNavButton {
        font-size: 18px;
    }

    .actionButtonsMobileDisplay {
        max-width: 50%;
    }

    #applyThemeButton,
    #submitNewBackgroundPictureButton,
    #submitNewProfilePictureButton,
    #submitNewDisplayNameButton,
    #submitNewEmailButton,
    #submitNewPasswordButton,
    #resetNewBackgroundPictureButton,
    #resetNewProfilePictureButton,
    #resetNewDisplayNameButton {
        width: 5rem;
    }
}

@media only screen and (max-width: 768px) {
    #userEmail {
        font-size: 22px;
    }

    .container {
        max-width: 100%;
    }

    .signOutImage {
        width: 5em;
    }

    .imageMobileDisplay {
        width: 5em;
    }

    #deleteThreadButton,
    #saveEditedThreadTitleButton,
    #cancelEditThreadTitleButton,
    #addLogBackButton,
    #addServiceLogButton,
    #addLogResetInputFieldsButton,
    #addLogSortLogsButton,
    #editVehicleNameButton,
    #printPageButton {
        margin: 4px;
        width: 8em;
    }

    #printPageViaDeleteButton {
        margin: 4px;
    }

    .logSubmitBtn,
    .logDeleteBtn {
        text-align: initial;
    }

    .innerBox {
        padding: initial;
        margin: initial;
    }

    .logDetailsMobileDisplay,
    .noWidthMobileDisplay {
        width: initial;
    }

    .removeMobileDisplay {
        display: none;
    }

    .showUnderMobileDisplay {
        color: black !important;
        display: initial;
    }

    .centerButtonMobileDisplay {
        text-align: center;
        margin-top: 1em;
    }

    .userInputErrorMessage {
        padding-left: initial;
        padding-right: initial;
    }

    #noMatchImage {
        width: 15em;
    }

    #profilePicture,
    #profilePicturePreview,
    #backgroundPicture {
        width: 55%;
    }

    .topActionButtonsEditDeleteMobileDisplay {
        margin-left: -10px;
        margin-top: 4px;
    }

    .bottomMarginMobileDisplay {
        margin-bottom: 1rem;
    }

    .largeBottomMarginMobileDisplay {
        margin-bottom: 8rem;
    }

    #mainPageProfilePicture {
        width: 4rem;
        height: 4rem;
    }

    #scrollableProfilePictureAccountDetails {
        margin-bottom: 2rem;
    }

    .section {
        background: rgba(255, 255, 255, 0.5);
    }

    .centerOnMobileDisplay {
        text-align: center !important;
    }

    .alignRightButtonsDesktopDisplay,
    .centerDesktopDisplay {
        text-align: initial;
    }

    .alignLeftButtonsMobileDisplay {
        text-align: left;
    }

    .paddingDesktopDisplay {
        padding-right: initial;
        padding-left: initial;
        margin-left: initial;
        margin-right: initial;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .smallNegativeTopMargin {
        margin-top: -1rem;
    }

    .noPaddingLeftDesktopDisplay {
        padding-left: 15px;
    }
    
    .noPaddingRightDesktopDisplay {
        padding-right: 15px;
    }

    .textLeftMobileDisplay {
        text-align: left !important;
    }

    #serviceLogBottomButtons {
        margin-top: 1rem;
    }

    .threadTitleTextArea {
        height: 13rem !important;
    }

    .threadDescriptionTextArea {
        height: 15rem !important;
    }

    .votes {
        text-align: left;
    }

    .noMarginMobileDisplay {
        margin-left: 0px;
        margin-right: 0px;
    }

    .smallMarginTopMobileDisplay {
        margin-top: 1rem;
    }

    .alignRightMobileDisplay {
        text-align: right;
    }

    #applyThemeButton,
    #submitNewBackgroundPictureButton,
    #submitNewProfilePictureButton,
    #submitNewDisplayNameButton,
    #submitNewEmailButton,
    #submitNewPasswordButton,
    #resetNewBackgroundPictureButton,
    #resetNewProfilePictureButton,
    #resetNewDisplayNameButton {
        width: 7rem;
    }

    .centerWidthMobileDisplay {
        width: 50%;
    }
}
    
@media only screen and (max-width: 575px) {
    #doNotSignOutButton,
    #doSignOutButton {
        width: 7em;
    }
}

@media only screen and (max-width: 414px) {

    .Toastify__toast-container--top-right {
        bottom: 4em;
        top: 0 !important;
    }

    .signOutImage {
        width: 3em;
    }
}

@media only screen and (max-width: 360px) {
    .navbar-brand {
        width: 70%;
    }

    #threadCommentTextArea {
        height: 10rem;
    }

    #updatedReleaseNotesTextArea,
    #updatedKnownIssuesTextArea {
        height: 6rem;
    }

    .updateChangesDeletePreview,
    .knownIssuesDeletePreview {
        max-height: 6rem;
        overflow: auto;
    }
}
